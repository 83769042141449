<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-05 11:08:02
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-01-28 17:59:34
 -->
<template>
  <div>
    <md-landscape v-model="showFullScreen"
                  full-screen>
      <div class="bxbox">
        <p class="bxtitle">{{setmark}}</p>
        <div class="libcontent">
          <div class="fullContent"></div>
        </div>
      </div>
    </md-landscape>
    <div class="baseinfo"
         v-if="type == 'baseinfo'">
      <md-field>
        <md-input-item title="推荐人信息"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-input-item title="推荐人姓名："
                       :solid="false"
                       readonly
                       v-model="user.tjname"
                       align="right"></md-input-item>
        <md-input-item title="推荐人工号："
                       v-model="user.tjempno"
                       :solid="false"
                       readonly
                       align="right"></md-input-item>
        <!-- <md-input-item title="上级主管信息"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-input-item title="上级主管姓名："
                       :solid="false"
                       readonly
                       v-model="user.upname"
                       align="right"></md-input-item>
        <md-input-item title="上级主管工号："
                       v-model="user.upempno"
                       :solid="false"
                       readonly
                       align="right"></md-input-item>
        <md-input-item title="育成人信息"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-input-item title="育成人姓名："
                       :solid="false"
                       readonly
                       v-model="user.ycname"
                       align="right"></md-input-item>
        <md-input-item title="育成人工号："
                       v-model="user.ycempno"
                       :solid="false"
                       readonly
                       align="right"></md-input-item> -->
        <md-input-item title="上传证件号(正、反面)、银行卡影像"
                       :solid="false"
                       disabled
                       class="tjmes require"></md-input-item>
        <ul class="image-reader-list">
          <li class="image-reader-item add"
              v-if="cardfront === ''">
            <md-image-reader name="reader0"
                             @select="oncardfrontSelect"
                             @complete="oncardfrontComplete"
                             @error="oncardfrontError"
                             is-multiple></md-image-reader>
            <md-icon name="camera"
                     size="lg"
                     color="#CCC"></md-icon>
            <p>证件正面</p>
          </li>
          <li class="image-reader-item"
              v-if="cardfront !== ''"
              :style="{
          'backgroundImage': `url(${cardfront})`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">
            <md-tag class="image-reader-item-del"
                    size="small"
                    v-if="checkstatus === 't'"
                    shape="quarter"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('cardfront')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
          <li class="image-reader-item add"
              v-if="cardback === ''">
            <md-image-reader name="reader0"
                             @select="oncardbackSelect"
                             @complete="oncardbackComplete"
                             @error="oncardbackError"
                             is-multiple></md-image-reader>
            <md-icon name="camera"
                     size="lg"
                     color="#CCC"></md-icon>
            <p>证件反面</p>
          </li>
          <li class="image-reader-item"
              v-if="cardback !== ''"
              :style="{
          'backgroundImage': `url(${cardback})`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">
            <md-tag class="image-reader-item-del"
                    size="small"
                    shape="quarter"
                    v-if="checkstatus === 't'"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('cardback')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
          <li class="image-reader-item add"
              v-if="bankcard === ''">
            <md-image-reader name="reader0"
                             @select="onbankcardSelect"
                             @complete="onbankcardComplete"
                             @error="onbankcardError"
                             is-multiple></md-image-reader>
            <md-icon name="camera"
                     size="lg"
                     color="#CCC"></md-icon>
            <p>银行卡</p>
          </li>
          <li class="image-reader-item"
              v-if="bankcard !== ''"
              :style="{
          'backgroundImage': `url(${bankcard})`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">
            <md-tag class="image-reader-item-del"
                    size="small"
                    shape="quarter"
                    v-if="checkstatus === 't'"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('bankcard')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
        </ul>

        <md-input-item title="上传学历证书和一寸免冠照影像"
                       :solid="false"
                       disabled
                       class="tjmes require"></md-input-item>
        <ul class="image-reader-list">
          <li class="image-reader-item add"
              v-if="edu === ''">
            <md-image-reader name="reader0"
                             @select="oneduSelect"
                             @complete="oneduComplete"
                             @error="oneduError"
                             is-multiple></md-image-reader>
            <md-icon name="camera"
                     size="lg"
                     color="#CCC"></md-icon>
            <p>学历证书</p>
          </li>
          <li class="image-reader-item"
              v-if="edu !== ''"
              :style="{
          'backgroundImage': `url(${edu})`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">
            <md-tag class="image-reader-item-del"
                    size="small"
                    v-if="checkstatus === 't'"
                    shape="quarter"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('edu')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
          <li class="image-reader-item add"
              v-if="headpic === ''">
            <md-image-reader name="reader0"
                             @select="onheadpicSelect"
                             @complete="onheadpicComplete"
                             @error="onheadpicError"
                             is-multiple></md-image-reader>
            <md-icon name="camera"
                     size="lg"
                     color="#CCC"></md-icon>
            <p>一寸免冠照</p>
          </li>
          <li class="image-reader-item"
              v-if="headpic !== ''"
              :style="{
          'backgroundImage': `url(${headpic})`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">
            <md-tag class="image-reader-item-del"
                    size="small"
                    v-if="checkstatus === 't'"
                    shape="quarter"
                    fill-color="#111A34"
                    type="fill"
                    font-color="#fff"
                    @click.native="onDeleteImage('headpic')">
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
          <li class="image-reader-item store"></li>
        </ul>
        <md-input-item title="申请人信息"
                       :solid="false"
                       disabled
                       class="tjmes"></md-input-item>
        <md-input-item title="所属机构："
                       class="require"
                       :solid="false"
                       readonly
                       v-model="jginfo"
                       align="right"></md-input-item>
        <md-input-item title="所属职级："
                       :solid="false"
                       readonly
                       v-model="degreename"
                       align="right"></md-input-item>
        <md-input-item title="姓名："
                       placeholder="姓名"
                       class="require"
                       :solid="false"
                       name="empname"
                       :readonly="checkstatus === 't'?false:true"
                       v-validate="'required'"
                       :error="errors.first('empname')"
                       v-model="form.salesmanInfo.empname"
                       align="right"></md-input-item>
        <md-field-item title="证件类型："
                       :content="cardtype"
                       @click="showcardSelector"
                       class="require"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>

        <md-selector v-model="cardSelectorShow"
                     :data="cardselectdata"
                     title="证件类型"
                     default-value="1"
                     large-radius
                     @choose="oncardtypeChoose"></md-selector>
        <md-input-item title="证件号码："
                       placeholder="证件号码"
                       :solid="false"
                       name="cardnum"
                       :readonly="checkstatus === 't'?false:true"
                       class="require"
                       @blur="inputcardnum"
                       v-validate="'required'"
                       :error="errors.first('cardnum')"
                       v-model="form.salesmanInfo.cardnum"
                       align="right"></md-input-item>
        <md-field-item title="性别："
                       :content="sex"
                       class="require"
                       @click="showsexSelector"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>

        <md-selector v-model="sexSelectorShow"
                     :data="sexselectdata"
                     title="性别"
                     large-radius
                     @choose="onsexChoose"></md-selector>
        <md-field-item title="民族："
                       :content="nation"
                       @click="shownationSelector"
                       class="require"
                       :disabled="checkstatus === 't'?false:true"
                       arrow
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>

        <md-selector v-model="nationSelectorShow"
                     max-height="400px"
                     :data="nationselectdata"
                     title="民族"
                     large-radius
                     @choose="onnationChoose"></md-selector>
        <md-field-item title="入职时间："
                       :solid="false"
                       class="require"
                       arrow="arrow-right"
                       align="right"
                       :content="form.salesmanInfo.indate"
                       @click.native="isinDateShow = true">
        </md-field-item>
        <md-date-picker ref="datePicker1"
                        v-model="isinDateShow"
                        v-if="checkstatus === 't'"
                        type="custom"
                        title="选择入职时间"
                        :default-date="currentDate"
                        large-radius
                        :custom-types="['yyyy', 'MM','dd']"
                        @change="oninDateChange"
                        @confirm="oninDateConfirm"></md-date-picker>

        <md-field-item title="出生日期："
                       :solid="false"
                       arrow="arrow-right"
                       align="right"
                       class="require"
                       :content="form.salesmanInfo.birth"
                       @click.native="isDatePickerShow = true"
                       placeholder="请选择">
        </md-field-item>
        <md-date-picker ref="datePicker2"
                        v-model="isDatePickerShow"
                        type="custom"
                        v-if="checkstatus === 't'"
                        title="选择出生日期"
                        :min-date="minDate"
                        large-radius
                        :custom-types="['yyyy', 'MM','dd']"
                        @change="onDatePickerChange"
                        @confirm="onDatePickerConfirm"></md-date-picker>
        <md-input-item title="详细地址："
                       placeholder="详细地址"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="form.salesmanInfo.zaddr"
                       align="right"></md-input-item>
        <md-input-item title="联系方式："
                       placeholder="联系方式"
                       :solid="false"
                       class="require"
                       :readonly="checkstatus === 't'?false:true"
                       name="tel"
                       v-validate="'required'"
                       :error="errors.first('tel')"
                       v-model="form.salesmanInfo.phone"
                       align="right"></md-input-item>
        <md-field-item title="政治面貌："
                       class="require"
                       :content="political"
                       :disabled="checkstatus === 't'?false:true"
                       @click="showepolitical"
                       arrow
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>
        <md-selector v-model="politicalShow"
                     :data="politicaldata"
                     title="政治面貌"
                     min-height="40vh"
                     max-height="40vh"
                     large-radius
                     @choose="politicalChoose"></md-selector>
        <md-field-item title="学历："
                       class="require"
                       :disabled="checkstatus === 't'?false:true"
                       :content="educational"
                       @click="showeduSelector"
                       arrow
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>

        <md-selector v-model="eduSelectorShow"
                     :data="eduselectdata"
                     title="学历"
                     large-radius
                     @choose="oneduChoose"></md-selector>
        <md-input-item title="邮箱："
                       placeholder="邮箱"
                       :solid="false"
                       :readonly="checkstatus === 't'?false:true"
                       v-model="form.salesmanInfo.email"
                       align="right"></md-input-item>
        <md-input-item title="银行卡信息"
                       class="tjmes"
                       disabled
                       :solid="false"></md-input-item>
        <md-field-item title="银行账户："
                       :content="bankcode"
                       @click="showbankSelector"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>
        <md-selector v-model="bankSelectorShow"
                     :data="bankselectdata"
                     title="银行账户"
                     large-radius
                     max-height="350px"
                     @choose="onbankChoose"></md-selector>
        <md-input-item title="银行卡号："
                       placeholder="银行卡号"
                       :solid="false"
                       v-model="form.salesmanInfo.bankno"
                       :readonly="checkstatus === 't'?false:true"
                       align="right"></md-input-item>
        <md-field-item title="开户地址："
                       :content="bankarea"
                       @click="showbankAreaSelector"
                       arrow
                       :disabled="checkstatus === 't'?false:true"
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>
        <md-picker v-model="bankAreaSelectorShow"
                     :data="bankAreaselectdata"
                     ref="bankAreaPicker"
                     title="开户地址"
                     :cols="3"
                     is-cascade
                     large-radius
                     max-height="350px"
                     @confirm="onbankAreaChoose"></md-picker> 
        <md-input-item title="开户支行："
                       placeholder="如招商银行深圳市罗湖支行"
                       :readonly="checkstatus === 't'?false:true"
                       :solid="false"
                       v-model="form.salesmanInfo.bankzh"
                       align="right"></md-input-item>
        <md-input-item title="手写签名"
                       class="tjmes require"
                       disabled
                       :solid="false"></md-input-item>
        <div style="background: #fff;padding: 10px;height: 6rem;position: relative">
          <div v-if="autograph === '' && selfautograph === '' && checkstatus === 't'"
               class="item_title"
               style="width: 100%;height:100%;border: 1px solid #f0f0f0;"
               id="contain"
               @touchstart="subtouchStart($event)">
            <VueSignaturePad ref="signaturePad" />
            <div style="position: absolute;right: 12px;top:-27px;color:#EC2D38"
                 @touchend="clearcanvas('qm')">删除</div>
          </div>
          <div class="signature item_title"
               style="width: 100%;height:100%;border: 1px solid #f0f0f0;"
               v-else>
            <img :src="autograph"
                 alt="签名"
                 style="width:100%;height:100%"
                 v-if="autograph">
            <div style="position: absolute;right: 12px;top:-27px;color:#EC2D38"
                 @touchend="clearcanvas('tp')"
                 v-if="!readonly">删除</div>
          </div>
        </div>
        <!-- :type="setvalue&&!agreeConf.checked?'disabled':'primary'"
         :style=" `backgroundColor:${colorprimary};`"-->
        <md-button :type="setvalue&&!agreeConf.checked?'disabled':'primary'"
                   :style=" `backgroundColor:${!agreeConf.checked?'colorprimary':''};`"
                   @click="saveform"
                   :loading="btnloading"
                   :inactive="inactive"
                   v-if="checkstatus === 't' || (checkstatus != 't' && btntext == '下一页')">{{btntext}}</md-button>
      </md-field>
    </div>
    <div class="rsxz"
           v-if="setvalue">
        <md-agree v-model="agreeConf.checked"
                  :disabled="agreeConf.disabled"
                  :size="agreeConf.size"
                  @change="changeAgree(agreeConf.name, agreeConf.checked, $event)">
          <p></p>
          <div>我已阅读并接受</div><span @click="getarticle()">《{{setmark}}》</span>
        </md-agree>
      </div>
    <gua v-if="type == 'gua'"
         :incardtype.sync="incardtype"
         :outcardtype.sync="outcardtype" />
    <edu v-if="type == 'edu'" />
    <family v-if="type == 'family'" />
    <work v-if="type == 'work'" />
    <div v-if="infod.isshowd == true" class="tsinfo">{{ infod.textd }}</div>
  </div>
</template>
<script>
import { getSelectList, selectBank, showEmpInfo, addmember, getInComSetting, ididentify, uploadfiles, bankidentify, getRelationInfo, selectBaseBankcode } from '@/api/agent/agent'
import { Dialog, Button, Landscape, Agree, DatePicker, InputItem, Field, Icon, ImageReader, Tag, Toast, Selector, FieldItem, TabPicker,Picker } from 'mand-mobile'
import gua from './guarantor'
import edu from './edu'
import family from './family'
import work from './work'
import { cloneDeep } from 'lodash'
import { getStorage } from '@/lib/util'
import areaList from '@/lib/area'
export default {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.tjempno = to.query.tjempno
      vm.degreename = to.query.degreename ? to.query.degreename : ''
      vm.form.salesmanInfo.tjempno = vm.tjempno
      vm.user.tjempno = vm.tjempno
      vm.form.salesmanInfo.degreeno = to.query.degreeno ? to.query.degreeno : ''
      vm.degreelevel = to.query.degreelevel ? to.query.degreelevel : '0'
      showEmpInfo().then(res => {
        vm.checkRytype = res.data.data.salesmanInfo.checkRytype
        if (vm.checkRytype === '5') {
          Dialog.alert({
            title: '确认',
            content: res.data.msg,
            confirmText: '确定',
            onConfirm: () => {
              vm.$router.push('/')
            }
          })
        }
        if (vm.checkRytype == '4') {
          vm.checkstatus = 't'
          vm.getRelation()
          vm.jginfo = res.data.data.tjpath
        } else {
          vm.readonly = true
          vm.form = res.data.data
          if (!res.data.data.guarantorInfo ||
            res.data.data.guarantorInfo.length == '0') {
            vm.form.guarantorInfo = [{ guatype: '0', guaempno: '', guaname: '', guacardtype: '', guacardnum: '', guazhiwu: '', guatel: '', guaaddr: '' }, { guatype: '1', guaempno: '', guaname: '', guacardtype: '', guacardnum: '', guazhiwu: '', guatel: '', guaaddr: '' }]
          } else if (res.data.data.guarantorInfo.length == 1) {
            if (res.data.data.guarantorInfo[0].guatype == '0') {
              res.data.data.guarantorInfo.push({
                guatype: '1',
                guaempno: '',
                guaname: '',
                guacardtype: '',
                guacardnum: '',
                guazhiwu: '',
                guatel: '',
                guaaddr: ''
              })
              vm.form.guarantorInfo = res.data.data.guarantorInfo
            } else {
              res.data.data.guarantorInfo.unhsift({
                guatype: '0',
                guaempno: '',
                guaname: '',
                guacardtype: '',
                guacardnum: '',
                guazhiwu: '',
                guatel: '',
                guaaddr: ''
              })
              vm.form.guarantorInfo = res.data.data.guarantorInfo
            }
          } else {
            vm.form.guarantorInfo = res.data.data.guarantorInfo
          }
          if (!vm.form.family) {
            vm.form.family = [{ famship: '', famshipName: '', name: '', cardtype: '', cardName: '', cardnum: '', famtel: '', famwork: '', famzhiwu: '' }]
          }
          if (!vm.form.edu) {
            vm.form.edu = [{ school: '', begdate: '', enddate: '', xueli: '', xueliname: '', zmr: '' }]
          }
          if (!vm.form.work) {
            vm.form.work = [{ workcompany: '', begdate: '', enddate: '', zhiwu: '', zmren: '', reason: '' }]
          }
          if (!vm.form.sourceinfo) {
            vm.form.sourceinfo = []
          }
          vm.form.guarantorInfo.forEach((item, index) => {
            vm.incardtype = vm.form.guarantorInfo[0].guacardname
            vm.outcardtype = vm.form.guarantorInfo[1].guacardname
          })
          vm.initpic()
          vm.jginfo = vm.form.salesmanInfo.fgsname
          vm.cardtype = vm.form.salesmanInfo.cardName
          vm.sex = vm.form.salesmanInfo.sexName
          vm.nation = vm.form.salesmanInfo.mzName
          vm.political = vm.form.salesmanInfo.zzmmName
          vm.educational = vm.form.salesmanInfo.eduName
          vm.checkstatus = vm.form.salesmanInfo.status
          vm.user.tjname = vm.form.salesmanInfo.tjname
          vm.user.tjempno = vm.form.salesmanInfo.tjempno
          vm.user.upname = vm.form.salesmanInfo.upname
          vm.user.upempno = vm.form.salesmanInfo.upempno
          vm.user.ycname = vm.form.salesmanInfo.ycname
          vm.user.ycempno = vm.form.salesmanInfo.ycempno
          vm.bankcode = vm.form.salesmanInfo.bankcode
          vm.bankarea = vm.form.salesmanInfo.bankarea.length>18?vm.form.salesmanInfo.bankarea.slice(0, 18) + '...': vm.form.salesmanInfo.bankarea
          vm.$forceUpdate()
          if (vm.checkRytype == '0') {
            vm.agreeConf.checked = true
            Dialog.alert({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {
              }
            })
          } else if (vm.checkRytype == '5') {
            Dialog.alert({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {
                vm.$router.push('/')
              }
            })
          } else if (vm.checkRytype === '4') {
            vm.checkstatus = 't'
          } else if (vm.checkRytype === '1') {
            vm.agreeConf.checked = true
            Dialog.failed({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {
              }
            })
          } else if (vm.checkRytype === '3') {
            Dialog.failed({
              title: '确认',
              content: res.data.msg,
              confirmText: '确定',
              onConfirm: () => {
                vm.checkstatus = 't'
                vm.readonly = false
              }
            })
          }
        }
        vm.getbtn()
      })
    })
  },
  components: {
    [InputItem.name]: InputItem,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [ImageReader.name]: ImageReader,
    [Tag.name]: Tag,
    [Selector.name]: Selector,
    [FieldItem.name]: FieldItem,
    [DatePicker.name]: DatePicker,
    [TabPicker.name]: TabPicker,
    [Picker.name]:Picker,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    gua,
    edu,
    family,
    work,
    [Agree.name]: Agree,
    [Landscape.name]: Landscape
  },
  data () {
    return {
      infod: {
        isshowd: false,
        textd: ''
      },
      btnloading: false,
      showFullScreen: false,
      inactive: false,
      setvalue: '',
      setmark: '',
      setcontent: '',
      num: 0,
      // 组件控制
      type: 'baseinfo',
      currenttype: ['baseinfo'],
      tjcode: '',
      jginfo: '',
      minDate: new Date('1940/1/1'),
      currentDate: new Date(),
      checkRytype: '',
      checkstatus: '',
      // 机构
      showjg: false,
      jglist: [],
      // 推荐人信息
      user: {
        tjname: '',
        tjempno: '',
        upname: '',
        upempno: '',
        ycname: '',
        ycempno: ''
      },
      // 保存按钮
      btntext: '',
      // 链接数据
      suserid: '',
      tjempno: '',
      tjempname: '',
      userid: '',
      form: {
        salesmanInfo: { tjcode: '', tjlist: [], empno: '', empname: '', cardtype: '', cardnum: '', indate: '', tjempno: '', tjname: '', upempno: '', upname: '', sex: '', nation: '', educational: '', political: '', birth: '', bankcode: '',bankarea:'', bankzh: '', bankno: '', zaddr: '', souhttp: '', phone: '', email: '', ycempno: '', ycname: '', degreeno: '' },
        guarantorInfo: [{ guatype: '0', guaempno: '', guaname: '', guacardtype: '', guacardnum: '', guazhiwu: '', guatel: '', guaaddr: '' }, { guatype: '1', guaempno: '', guaname: '', guacardtype: '', guacardnum: '', guazhiwu: '', guatel: '', guaaddr: '' }],
        family: [{ famship: '', name: '', cardtype: '', cardnum: '', famtel: '', famwork: '', famzhiwu: '' }],
        edu: [{ school: '', begdate: '', enddate: '', xueli: '', zmr: '' }],
        work: [{ workcompany: '', begdate: '', enddate: '', zhiwu: '', zmren: '', reason: '' }],
        sourceinfo: []
        
      }, // 申请表单
      // 图片相关
      cardfront: '',
      cardback: '',
      bankcard: '',
      edu: '',
      headpic: '',
      accepic: '',
      // 签名照
      signature: '',
      readonly: false,
      // 下拉框
      cardtype: '',
      sex: '',
      nation: '',
      educational: '',
      political: '',
      politicstatus: '',
      mtcsol: '',
      bankcode: '',
      bankarea:'',
      cardselectdata: [
        {
          value: '1',
          text: '选项一'
        }
      ],
      cardSelectorShow: false,
      sexSelectorShow: false,
      nationSelectorShow: false,
      politicalShow: false,
      eduSelectorShow: false,
      poliSelectorShow: false,
      mtcsolSelectorShow: false,
      bankSelectorShow: false,
      bankAreaSelectorShow: false,
      isDatePickerShow: false,
      isinDateShow: false,
      nationselectdata: [],
      sexselectdata: [],
      eduselectdata: [],
      politicaldata: [],
      familyshipdata: [],
      poliselectdata: [
        {
          value: '0',
          text: '共青团员'
        }
      ],
      mtcsolselectdata: [
        {
          value: '0',
          text: '专职'
        },
        {
          value: '1',
          text: '兼职'
        }
      ],
      bankselectdata: [
        {
          value: '0',
          text: '中国工商银行'
        }
      ],
      bankAreaselectdata: areaList,
      datePickerValue: '',
      // 入司开关
      incomset: {},
      canvas: null,
      cxt: null,
      stage_info: null,
      selfcardfront: '',
      selfcardback: '',
      selfbankcard: '',
      selfedu: '',
      selfheadpic: '',
      autograph: '',
      selfautograph: '',
      degreelevel: '0',
      degreename: '',
      // 入司须知
      isClick:false,
      time:'',
      agreeConf: {
        checked: false,
        name: 'agree1',
        size: 'lg',
        disabled: false,
        introduction: '未选中状态'
      },
      // 子组件翻译字段
      incardtype: '',
      outcardtype: '',
      getossurl: '',
      colorprimary: ''
    }
  },
  created () {
    this.getossurl = getStorage('ossurl', '')
    console.log(areaList[0].value);
    this.colorprimary = getStorage('theme', '')
    this.rsxz = getStorage('RSXZ', '') ? getStorage('RSXZ', '') : ''
    this.setvalue = this.rsxz.setvalue ? this.rsxz.setvalue : false
    this.setmark = this.rsxz.setmark
    this.setcontent = this.rsxz.setcontent
    getSelectList({ list: ['XUELI', 'RSCARD', 'MINZHU', 'SEX', 'YWSHIP', 'ZZMM'] }).then(res => {
      res.data.data.forEach(item => {
        switch (item.label) {
          case 'RSCARD':
            this.cardselectdata = item.value
            this.cardselectdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'YWSHIP':
            this.familyshipdata = item.value
            this.familyshipdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'MINZHU':
            this.nationselectdata = item.value
            this.nationselectdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'SEX':
            this.sexselectdata = item.value
            this.sexselectdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'XUELI':
            this.eduselectdata = item.value
            this.eduselectdata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          case 'ZZMM':
            this.politicaldata = item.value
            this.politicaldata.forEach(item => {
              item.value = item.code
              item.text = item.name
            }
            )
            break
          default:
            break
        }
      })
    })
    selectBaseBankcode().then((res) => {
      this.bankselectdata = res.data.data
      this.bankselectdata.forEach(item => {
        item.value = item.bankid
        item.text = item.bankname
      })
    })
  },
  mounted () {
    let month = (new Date().getMonth() + 1) < 10 ? ('0' + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1)
    let day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
    this.form.salesmanInfo.indate = new Date().getFullYear() + '-' + month + '-' + day
    this.$nextTick(() => {
      // window.document.title = this.title
      $('.fullContent').load(this.setcontent)
    })
    if (this.$refs.signaturePad) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.signaturePad.$refs.signaturePadCanvas.width = document.getElementById('contain').offsetWidth
          this.$refs.signaturePad.$refs.signaturePadCanvas.height = document.getElementById('contain').offsetHeight
        }, 1500)
      })
    }
  },
  methods: {
    changeAgree(name,checked){
      console.log(this.isClick)
      if(this.isClick==true){
        this.agreeConf.checked=checked
      }else{
        Toast.info('请先阅读协议后勾选')
        this.agreeConf.checked=false
        clearInterval(this.time)
      }
    },
    subtouchStart (event) {
      event.preventDefault()
    },
    getbtn () {
      getInComSetting().then(res => {
        this.incomset = res.data.data.Setcontent
        if (this.incomset.internalGuarantor || this.incomset.outsideGuarantor || this.incomset.familys || this.incomset.educations || this.incomset.works) {
          this.btntext = this.checkstatus == 't' ? '下一步' : '下一页'
        } else { this.btntext = '提交申请' }
      })
    },
    getRelation () {
      getRelationInfo({ empno: this.tjempno, level: this.degreelevel }).then((res) => {
        let Data = res.data.data
        this.user.tjname = Data.tjEmpnoInfo.empname
        this.user.upname = Data.upEmpnoInfo.upname
        this.user.upempno = Data.upEmpnoInfo.upempno
        this.user.ycname = Data.ycEmpnoInfo.ycname
        this.user.ycempno = Data.ycEmpnoInfo.ycempno
        this.jginfo = Data.tjEmpnoInfo.fgstjname
        this.form.salesmanInfo.tjcode = Data.tjEmpnoInfo.tjcode
        this.form.salesmanInfo.tjname = Data.tjEmpnoInfo.empname
        this.form.salesmanInfo.upname = Data.upEmpnoInfo.upname
        this.form.salesmanInfo.upempno = Data.upEmpnoInfo.upempno
        this.form.salesmanInfo.ycname = Data.ycEmpnoInfo.ycname
        this.form.salesmanInfo.ycempno = Data.ycEmpnoInfo.ycempno
      })
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // 转换成file对象
      return new File([u8arr], filename, { type: mime })
    },
    async saveform () {
      // 上传签名
      // 用file文件来上传oss，获取段路径
      if (this.autograph === '' && this.selfautograph === '') {
        const { data } = cloneDeep(this.$refs.signaturePad.saveSignature())
        if (data) {
          let file = data // 把整个base64给file
          let name = '签名' + '.png' // 定义文件名字（例如：abc.png ， cover.png）
          let conversions = this.dataURLtoFile(file, name) // 调用base64转图片方法
          // conversions就是转化之后的图片文件，
          let formdata = new FormData()
          formdata.append('file', conversions)
          formdata.append('type', 'autograph')
          console.log(formdata);
          await uploadfiles(formdata).then(res => {
            this.selfautograph = res.data.data.path
            this.autograph = res.data.data.url
          })
        }
      }
      this.currenttype = ['baseinfo']
      this.$validator.validateAll().then(valid => {
        if (valid) {
          if (this.cardfront === '' || this.cardback === '' || this.bankcard === '' || this.headpic === '' || this.edu === '') {
            Toast.failed('请上传影像(红星标注)')
          } else {
            if (this.form.salesmanInfo.tjcode === '' || this.form.salesmanInfo.cardtype === '' ||
              this.form.salesmanInfo.sex === '' || this.form.salesmanInfo.nation === '' ||
              this.form.salesmanInfo.indate === '' || this.form.salesmanInfo.birth === '' ||
              this.form.salesmanInfo.educational === '' || this.form.salesmanInfo.political === '') {
              this.infod.textd = '请填写完整必填信息(红星标注)'
              this.infod.isshowd = true
              setTimeout(() => {
                this.infod.isshowd = false
              }, 2000)
            } else if (this.autograph === '') {
              Toast.failed('请手写签名')
            } else {
              if (this.form.sourceinfo.length === 0) {
                this.form.sourceinfo = []
                this.form.sourceinfo.push({ souhttp: this.selfcardfront, soucode: 'idcardq' })
                this.form.sourceinfo.push({ souhttp: this.selfcardback, soucode: 'idcardh' })
                this.form.sourceinfo.push({ souhttp: this.selfbankcard, soucode: 'bank' })
                this.form.sourceinfo.push({ souhttp: this.selfedu, soucode: 'xueli' })
                this.form.sourceinfo.push({ souhttp: this.selfheadpic, soucode: 'headone' })
                this.form.sourceinfo.push({ souhttp: this.selfautograph, soucode: 'autograph' })
              } else {
                this.form.sourceinfo.forEach((item, index) => {
                  if (item.soucode == 'idcardq') {
                    item.souhttp = this.selfcardfront
                  } else if (item.soucode == 'idcardh') {
                    item.souhttp = this.selfcardback
                  } else if (item.soucode == 'bank') {
                    item.souhttp = this.selfbankcard
                  } else if (item.soucode == 'xueli') {
                    item.souhttp = this.selfedu
                  } else if (item.soucode == 'headone') {
                    item.souhttp = this.selfheadpic
                  } else if (item.soucode == 'autograph') {
                    item.souhttp = this.selfautograph
                  }
                })
              }
              if (this.incomset.internalGuarantor || this.incomset.outsideGuarantor) {
                this.type = 'gua'
              } else if (this.incomset.familys) {
                this.type = 'family'
              } else if (this.incomset.educations) {
                this.type = 'edu'
              } else if (this.incomset.works) {
                this.type = 'work'
              } else {
                for (let i = 0; i < this.form.guarantorInfo.length; i++) {
                  for (let Guarantor in this.form.guarantorInfo[i]) {
                    if (Guarantor !== 'guatype') {
                      if (this.form.guarantorInfo[i][Guarantor] !== '') {
                        this.form.guarantorInfo[i].guatype = i
                        break
                      } else {
                        this.form.guarantorInfo[i].guatype = ''
                        break
                      }
                    }
                  }
                }
                this.btnloading = true
                this.inactive = true
                this.form.salesmanInfo.indateStr = this.form.salesmanInfo.indate // 传参需要indateStr参数，回显需要indate参数
                this.form.salesmanInfo.birthStr = this.form.salesmanInfo.birth // 传参需要birthStr参数，回显需要birth参数
                addmember(this.form).then(res => {
                  if (res.data.code == 200) {
                    this.btnloading = false
                    this.inactive = false
                    Toast.succeed('入司申请成功')
                    this.$router.push('/successpage')
                  }
                }).finally(() => {
                  this.btnloading = false
                  this.inactive = false
                })
              }
            }
          }
        }
      })
    },
    // 入司须知按钮
    getarticle () {
      // this.$router.push({
      //   path: `/rzxzdetails?setmark=${title}&setcontent=${desc}`
      // })
      this.showFullScreen = true
      this.time=setInterval(()=>{
          this.isClick=true
          clearInterval(this.time)
        },5000) 
    },
    // 删除图片
    onDeleteImage (tag) {
      switch (tag) {
        case 'bankcard':
          this.bankcard = ''
          this.selfbankcard = ''
          break
        case 'cardfront':
          this.cardfront = ''
          this.selfcardfront = ''
          break
        case 'cardback':
          this.cardback = ''
          this.selfcardback = ''
          break
        case 'edu':
          this.edu = ''
          this.selfedu = ''
          break
        case 'headpic':
          this.headpic = ''
          this.selfheadpic = ''
          break
        default:
          break
      }
    },
    // 图片选择
    oncardfrontSelect () { Toast.loading('图片读取中...') },
    oncardfrontComplete (name, { dataUrl, file }) {
      Toast.hide()
      let yzformdata = new FormData()
      yzformdata.append('file', file)
      yzformdata.append('type', '0')
      ididentify(yzformdata).then(res => {
        this.form.salesmanInfo.empname = res.data.data.name
        this.form.salesmanInfo.cardnum = res.data.data.idCard
        this.form.salesmanInfo.birth = this.form.salesmanInfo.cardnum.slice(6, 10) + '-' + this.form.salesmanInfo.cardnum.slice(10, 12) + '-' + this.form.salesmanInfo.cardnum.slice(12, 14)
        this.form.salesmanInfo.cardtype = '10'
        this.cardtype = '身份证'
        this.form.salesmanInfo.zaddr = res.data.data.address
        this.form.salesmanInfo.nation = res.data.data.nation
      })
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'idcard')
      uploadfiles(formdata).then(res => {
        this.selfcardfront = res.data.data.path
        this.cardfront = res.data.data.url
      })
    },
    oncardfrontError () { },
    oncardbackSelect () { Toast.loading('图片读取中...') },
    oncardbackComplete (name, { dataUrl, file }) {
      Toast.hide()
      let yzformdata = new FormData()
      yzformdata.append('file', file)
      yzformdata.append('type', '1')
      ididentify(yzformdata).then(res => {
        Toast.info(res.data.msg, 1500, true)
      })
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'idcard')
      uploadfiles(formdata).then(res => {
        this.selfcardback = res.data.data.path
        this.cardback = res.data.data.url
      })
    },
    oncardbackError () { },
    onbankcardSelect () { Toast.loading('图片读取中...') },
    onbankcardComplete (name, { dataUrl, file }) {
      Toast.hide()
      let formdata = new FormData()
      formdata.append('file', file)
      bankidentify(formdata).then(res => {
        // eslint-disable-next-line no-unused-expressions
        if (!res.data.data) {
          Toast.info('图片未识别成功')
          return
        }
        this.form.salesmanInfo.bankcode = ''
        this.bankcode = ''
        this.form.salesmanInfo.bankno = ''
        this.form.salesmanInfo.bankno = res.data.data && res.data.data.bank_card_number ? res.data.data.bank_card_number : ''
        let bankcode = res.data.data ? res.data.data.bank_name : ''
        this.bankselectdata.forEach(item => {
          if (item.text == bankcode) {
            this.form.salesmanInfo.bankcode = item.value
            this.bankcode = item.text
          }
        })
      })
      formdata.delete('type')
      formdata.append('type', 'bank')
      uploadfiles(formdata).then(res => {
        this.selfbankcard = res.data.data.path
        this.bankcard = res.data.data.url
      })
    },
    onbankcardError () { },
    oneduSelect () { Toast.loading('图片读取中...') },
    oneduComplete (name, { dataUrl, file }) {
      Toast.hide()
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'xueli')
      uploadfiles(formdata).then(res => {
        this.selfedu = res.data.data.path
        this.edu = res.data.data.url
      })
    },
    oneduError () { },
    onheadpicSelect () { Toast.loading('图片读取中...') },
    onheadpicComplete (name, { dataUrl, file }) {
      Toast.hide()
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'headone')
      uploadfiles(formdata).then(res => {
        this.selfheadpic = res.data.data.path
        this.headpic = res.data.data.url
      })
    },
    onheadpicError () { },
    onacceSelect () { Toast.loading('图片读取中...') },
    onacceComplete (name, { dataUrl, file }) {
      Toast.hide()
      this.accepic = dataUrl
    },
    onacceError () { },
    // 下拉框
    showcardSelector (selectag) {
      this.cardSelectorShow = true
    },
    oncardtypeChoose ({ value, text }) {
      this.form.salesmanInfo.cardtype = value
      this.cardtype = text
      /* this.selectdata.cardtype = text */
      let cardnum = this.form.salesmanInfo.cardnum
      if (cardnum.length === 18) {
        this.form.salesmanInfo.birth = cardnum.slice(6, 10) + '-' + cardnum.slice(10, 12) + '-' + cardnum.slice(12, 14)
      }
    },
    onsexChoose ({ value, text }) {
      this.form.salesmanInfo.sex = value
      this.sex = text
      /* this.selectdata.sex = text */
    },
    showsexSelector () {
      this.sexSelectorShow = true
    },
    shownationSelector () {
      this.nationSelectorShow = true
    },
    onnationChoose ({ value, text }) {
      this.form.salesmanInfo.nation = value
      this.nation = text
      /* this.selectdata.nation = text */
    },
    showepolitical () {
      this.politicalShow = true
    },
    showeduSelector () {
      this.eduSelectorShow = true
    },
    oneduChoose ({ value, text }) {
      this.form.salesmanInfo.educational = value
      this.educational = text
      /* this.selectdata.educational = text */
    },
    politicalChoose ({ value, text }) {
      this.form.salesmanInfo.political = value
      this.political = text
      /* this.selectdata.educational = text */
    },
    showpoliSelector () { this.poliSelectorShow = true },
    onpoliChoose ({ value, text }) {
      this.form.politicstatus = value
      this.politicstatus = text
    },
    showmtcsolSelector () { this.mtcsolSelectorShow = true },
    onmtcsolChoose ({ value, text }) {
      this.form.mtcsol = value
      this.mtcsol = text
    },
    showbankSelector () { this.bankSelectorShow = true },
    onbankChoose ({ value, text }) {
      this.form.salesmanInfo.bankcode = value
      this.form.salesmanInfo.bankcode = value
      this.bankcode = text
    },
    onbankAreaChoose(){
      const values = this.$refs[`bankAreaPicker`].getColumnValues()
      console.log(values);
      let res = ''
      values.forEach(value => {
        value && (res += `${value.text || value.label} `)
      })
      console.log(res);
      this.form.salesmanInfo.bankarea = res
      this.bankarea = res.length>18?res.slice(0, 18) + '...': res
    },
    clearcanvas (type) {
      /*
      * 清空签名
      * */
      if (type == 'qm') {
        this.$refs.signaturePad.undoSignature()
      } else {
        this.autograph = ''
        this.selfautograph = ''
      }
    },
    // 出生日期
    onDatePickerChange () { },
    onDatePickerConfirm (columnsValue) {
      this.form.salesmanInfo.birth = this.$refs.datePicker2.getFormatDate('yyyy-MM-dd')
    },
    oninDateChange () { },
    oninDateConfirm () {
      this.form.salesmanInfo.indate = this.$refs.datePicker1.getFormatDate('yyyy-MM-dd')
    },
    inputcardnum () {
      let cardnum = this.form.salesmanInfo.cardnum
      if (cardnum.length === 18) {
        if (this.cardtype === '居民身份证') {
          this.form.salesmanInfo.birth = cardnum.slice(6, 10) + '-' + cardnum.slice(10, 12) + '-' + cardnum.slice(12, 14)
        }
      }
    },
    initpic () {
      if (this.form.sourceinfo.length !== 0) {
        this.form.sourceinfo.forEach(item => {
          switch (item.soucode) {
            case 'idcardq':
              this.cardfront = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfcardfront = item.souhttp
              break
            case 'idcardh':
              this.cardback = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              //   this.getossurl + item.souhttp
              this.selfcardback = item.souhttp
              break
            case 'bank':
              this.bankcard = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfbankcard = item.souhttp
              break
            case 'xueli':
              this.edu = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfedu = item.souhttp
              break
            case 'headone':
              // this.headpic = this.getossurl + item.souhttp
              this.headpic = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfheadpic = item.souhttp
              break
            case 'autograph':
              this.autograph = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
              this.selfautograph = item.souhttp
              break
            default:
              break
          }
        })
      }
    },
    // 开户地址
    showbankAreaSelector(){
      this.bankAreaSelectorShow = true
    }
  }
}
</script>
<style lang="stylus" scoped>
/deep/ .md-field-item-title {
  padding-left: 2.7vw;
}

/deep/ .require {
  .md-field-item-title::after {
    content: '*';
    color: red;
  }
}

/deep/ .md-field-item-control {
  color: #111a34 !important;
}

/deep/ .tjmes {
  .md-field-item-title {
    padding-left: 0;
    font-weight: bold;
  }
}

/deep/ .md-field-item-placeholder {
  color: #c5cad5;
  font-weight: 400;
  font-size: 0.32rem;
  position: relative;
  bottom: 0.01rem;
}

.rsxz {
  text-align: center;
  font-size: 0.32rem;
  padding: 0.8rem 0;
  display: flex;
  justify-content: center;
  flex-direction: row;

  div {
    display: inline-block;
    font-size: 0.32rem;
  }

  span {
    color: #2d8cf0;
    font-size: 0.32rem;
    display: inline-block;
    margin: 0 0.2rem;
  }

  .md-agree {
    display: flex;
  }

  .md-agree-icon .md-agree-icon-container {
    left: 1.9rem !important;
  }
}

.image-reader-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bxbox {
  padding: 0.2rem;
}

.bxtitle {
  font-size: 0.45rem;
  font-weight: bold;
  text-align: center;
  padding: 0.4rem 0.5rem 0;
}
/deep/ .list-paddingleft-2{
  width: 94vw !important;
  line-height: 0.65rem;
  padding: 0.3rem 0.3rem;
}
.autographlandscape {
  /deep/ .md-popup-mask{
    background-color: rgba(255,255,255,1);
  }
  /deep/.md-landscape-content{
    width: 96vw;
    height: 68vh;
    /deep/img{
      max-width: 100vw;
      margin-top: 24vh;
      height: auto;
    }
  }
  /deep/ .md-icon.md-landscape-close{
    margin-top:5vh;
    color: #000;
  }
}

.image-reader-item {
  position: relative;
  float: left;
  width: 30%;
  padding-bottom: 23.5%;
  margin-bottom: 2%;
  background: #FFF;
  box-shadow: 0 5px 20px rgba(197, 202, 213, 0.25);
  box-sizing: border-box;
  list-style: none;
  border-radius: 4px;
  background-size: cover;
  overflow: hidden;

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  &.add {
    .md-icon {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.8;
    }

    p {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: 15px;
      font-size: 22px;
      color: #CBC;
      text-align: center;
    }
  }

  .image-reader-item-del {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    opacity: 0.8;

    .md-icon-close {
      font-size: 24px;
    }
  }
}

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}

.store {
  border: none;
  box-shadow: none;
}
.tsinfo {
  z-index: 999
  background-color: rgba(65, 72, 93, 0.77);
  width: 54%;
  position: fixed;
  top: 50%;
  left: 23%;
  padding: 0.2rem 0.3rem;
  border-radius: 0.04rem;
  color: white;
}
</style>
